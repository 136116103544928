<template>
    <div class="my_layout ZAfont_family ZAfont_size">
        <div v-if="userProfile.memberOf.includes('ZAlift_Lvl_1_Product_Configuration')||userProfile.memberOf.includes('ZAlift_Lvl_2_Inquiry')||userProfile.memberOf.includes('ZAlift_Lvl_3_Purchasing')">
            <h1 class="my_color_00338e"><span class="my_font-weight_700">{{$t('1')}}</span> {{$t('3281')}}</h1>
            <div class="ZAfont_family" style="font-size: 12px;">{{databaseversion}}</div>
            <div class="ZAfont_family ZAfont_size">{{$t('3282')}}</div>
            <hr />
            <div style="display:grid; grid-template-columns:2fr 1fr;">
                <div style="padding-right:25px;border-right: 1px solid rgba(0, 0, 21, 0.2)">
                    <h4 class="my_color_00338e my_font-weight_600">{{$t('3328')}}</h4>
                    <div>
                        <div style="margin-top:5px">
                            <div class="ZAfont_family ZAfont_size">{{$t('3329')}}</div>
                        </div>
                        <div style="margin-top: 1px; margin-bottom: 20px">
                            <input v-if="userProfile.memberOf.includes('ZAEmployee')==true" type="file" hidden id="upload-btn" @change="check_configuration_fromFile" accept=".html, .aus, .xml" />
                            <input v-if="userProfile.memberOf.includes('ZAEmployee')==false" type="file" hidden id="upload-btn" @change="check_configuration_fromFile" accept=".html, .aus" />
                            <label for="upload-btn" class="btn my_btn_standard_blue">{{$t('3330')}}</label>
                        </div>
                    </div>
                    <!--<div class="ZAfont_family ZAfont_size">{{$t('3329')}}</div>-->
                    <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr;">
                        <div style="padding-left: 5px;position:relative;grid-column:1 / span 2">
                            <div class="ZAfont_family ZAfont_size" style="position:absolute; bottom:0px">{{$t('1107')}}</div>
                        </div>
                        <div style="padding-left: 5px; position:relative">
                            <div class="ZAfont_family ZAfont_size" style="position:absolute; bottom:0px">{{$t('3334')}}</div>
                        </div>
                        <div v-if="acceptCustomerNumber==true" style="padding-left:5px;position:relative">
                            <div class="ZAfont_family ZAfont_size" style="position:absolute; bottom:0px">{{$t('3374')}}</div>
                        </div>
                    </div>
                    <div style="display:grid; grid-template-columns:1fr 1fr 1fr 1fr;">
                        <div style="display: flex; margin: 0; padding: 0 0 0 5px; align-items: stretch">
                            <input v-model="calculationNumber" class="my_input" />
                        </div>
                        <div>
                            <button @click="searchCalculationNumber" class="btn my_btn_standard_blue">{{$t('1186')}}</button>
                        </div>
                        <div style="display: flex; margin: 0; padding: 0 5px 0 5px;">
                            <input v-model="searchNote" class="my_input" />
                        </div>
                        <div v-if="acceptCustomerNumber==true" style="display: flex; margin: 0; padding: 0 5px 0 5px;">
                            <div>
                                <input v-model="customerNumber" class="my_input" />
                            </div>
                            <div>
                                <button @click="searchCustomerNumber" class="btn my_btn_standard_blue">Suchen</button>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div>
                        <div class="width1400">
                            <ConfigurationsTable></ConfigurationsTable>
                        </div>
                        <!--<div class="ZAfont_family ZAfont_size">... {{$t('3331')}}</div>
                <div v-if="loader_forLoadingResults_at_configurationsOverview_Shown==true" style="margin-top:10px">
                    <LoaderIcon v-bind:loaderSize="'lg'"></LoaderIcon>
                </div>
                <div v-else>
                    <div v-if="(statusAt_responseOf_select_databaseRecord_from_configurationOverviews.hasOwnProperty('statusCode'))&&(statusAt_responseOf_select_databaseRecord_from_configurationOverviews.statusCode.startsWith('2'))">
                        <div v-if="configurationsOverviewList.length>0">
                            <table class="my_table_configurationsOverview ZAfont_family ZAfont_size" style="margin-top:15px;">
                                <thead>
                                    <tr style="display:flex;">
                                        <th style="flex:0 0 10%; text-align:left">{{$t('3332')}}</th>
                                        <th style="flex:0 0 25%; text-align:left">{{$t('3334')}}</th>
                                        <th style="flex:0 0 15%; text-align:center">{{$t('3333')}}</th>
                                        <th style="flex:0 0 22%; text-align:right">{{$t('3335')}}</th>
                                        <th style="flex:0 0 22%; text-align:right">{{$t('3336')}}</th>
                                        <th style="flex:0 0 5%; text-align:right">     </th>
                                    </tr>
                                </thead>
                                <tbody style="height:250px;overflow-y:scroll;cursor:pointer">
                                    <tr v-for="configuration in configurationsOverviewList" @click="rowClick(configuration, $event)" style="display:flex;">
                                        <td style="flex:0 0 10%; text-align:left">{{configuration.zAlift_ID}}</td>
                                        <td style="flex:0 0 25%; text-align:left">
                                            <span v-if="configuration.note!=null">{{configuration.note}}</span>
                                            <span v-else>-</span>
                                        </td>
                                        <td style="flex:0 0 15%; text-align:center">
                                            <span v-if="configuration.configuration_Modifiable==false">{{$t('3337')}}</span>
                                            <span v-else>{{$t('3338')}}</span>
                                        </td>
                                        <td style="flex:0 0 22%; text-align:right">{{configuration.configuration_LastEditedOn}}</td>
                                        <td style="flex:0 0 22%; text-align:right">{{configuration.user_MailAddress}}</td>
                                        <td v-if="configuration.configuration_Modifiable==false" style="flex: 0 0 5%; text-align: right;"><span><i class="fa-light fa-share-nodes"></i></span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-else>
                            <div style="display:inline-block;border:1px solid grey; padding:0.75rem; margin-top:10px">
                                <div class="my_color_00338e"><i class="fal fa-info-square fa-2x"></i></div>
                                <div>{{$t('3339')}}</div>
                                <a @click="furtherToConfiguration" href="#">{{$t('3298')}}</a>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div style="display:inline-block;border:1px solid grey; padding:0.75rem; margin-top:10px">
                            <div class="my_color_00338e"><i class="fal fa-info-square fa-2x"></i></div>
                            <div>{{$t('3351')}}</div>
                            <a @click="select_databaseRecord_from_configurationOverviews" href="#">{{$t('3256')}}</a>
                        </div>
                    </div>
                </div>-->
                    </div>
                </div>
                <div style="padding-left:25px">
                    <h4 class="my_color_00338e my_font-weight_600">{{$t('3283')}}</h4>
                    <button @click="furtherToConfiguration" class="btn my_btn_standard_blue" style="margin-top:20px">{{$t('3298')}}</button>

                    <!-- Test Dimmler -->
                   
                    <hr />
                    <div v-if="sapProfile==true">
                        <h4 class="my_color_00338e my_font-weight_600">Produktkonfiguration ohne technische Auslegung</h4>
                        <button @click="TestDimmlerFI" class="btn my_btn_standard_blue" style="margin-top:20px">{{$t('3298')}}</button>
                        <hr />
                    </div>
                    <div v-if="shareDisplayShow == true" id="shareDisplay" style="background-color:rgba(0,0,0,0.05);">
                        <label>{{$t('3355')}}</label> &nbsp;
                        <input id="idAddToShareList" type="text" /> &nbsp;
                        <i @click="addLink()" style="cursor:pointer" class="fa-solid fa-plus"></i>
                        <hr />
                        <div id="shared" style="height:250px;overflow-y:scroll;">
                            <table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>{{$t('3356')}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="sl in sharedList" @click="removeLink(sl, $event)">
                                        <td><span><i style="cursor:pointer" class="fa-solid fa-minus"></i>&nbsp; </span></td>
                                        <td>{{sl}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <!--<span><i class="fa-solid fa-minus"></i>&nbsp; </span><br/>-->
                        </div>
                        <a style="float:right; cursor:pointer" @click="close()"><i class="fa-solid fa-x"></i></a>
                    </div>
                </div>

            </div>
           
            <div class="width1400_lg" style="display:none">
                <ConfigurationsTable></ConfigurationsTable>
            </div>
        </div>
        <div v-else>
            <div class="ZAfont_family ZAfont_size">{{$t('3415')}}</div>
            <button @click="logout" class="btn my_btn_standard_blue" style="margin-top:10px">{{$t('3242')}}</button>
        </div>
        <!--<hr />-->
        <!--<div id="overlay">
            <button>dsds</button>
        </div>-->
    </div>
</template>

<script>
    //"check_configuration_fromDatabase(configuration)"
    import axios from 'axios';
    import router from '../router';
    import LoaderIcon from './loader/LoaderIcon';
    import ConfigurationsTable from './configuration/ConfigurationsTable.vue';

    export default {
        name: 'Home',
        props: {
            zaliftidFromUrl: {
                type: String,
                default: null
            },
            salesorderFromUrl: {
                type: String,
                default: null
            },
            posFromUrl: {
                type: String,
                default: null
            }
        },
        components: {
            LoaderIcon,
            ConfigurationsTable,
        },

        data() {
            return {
                img: this.$imageFilePath,

                configurationsOverviewList: [],

                sharedList: ["example@email.com", "dkdkd@com.de"],
                tempZALift_ID: "",
                calculationNumber: null,
                note: null,
                customerNumber: null,
            }
        },
        created() {
            this.$store.dispatch('configurations/get_plusManual_storeAction');

            if (this.salesorderFromUrl != null) {
                this.$store.dispatch('configurations/change_configurations_salesOrderRelation_storeAction', {
                    salesOrder: this.salesorderFromUrl,
                    pos: this.posFromUrl
                });
            }
            //this.select_databaseRecord_from_configurationOverviews();
            this.$store.commit('configurations/change_configurationNotValid_storeMutation', null)
            this.$store.dispatch('configurations/change_manualConfiguration_storeAction', false);
            this.$store.dispatch('configurations/MANU', false);

            this.$store.dispatch('configurations/change_elevatorMotor_Manual_storeAction', false);
            this.$store.dispatch('inputs/change_inputs_toDefault_storeAction');
            this.$store.dispatch('driveTechnologies/change_driveTechnologies_toDefault_storeAction');
            this.$store.dispatch('controlTechnologies/change_controlTechnologies_toDefault_storeAction');
            this.$store.dispatch('frames/change_frames_toDefault_storeAction')
            this.$store.dispatch('summary/change_summary_toDefault_storeAction');
            this.$store.dispatch('configurations/change_configurations_toDefault_storeAction');
            this.$store.dispatch('manualHome/change_ZLM_storeAction', null);
            this.$store.dispatch('inputsManual/change_manualMotorToDefault_storeAction');
            this.$store.commit('inputsManual/change_showMotorDetails_storeMutation', true);
        },
        computed: {
            sapProfile: {
                get() {
                    return this.$store.state.configurations.sap;
                }
            },
            userProfile: {
                get() {
                    return this.$store.state.account.user.profile
                }
            },
            acceptCustomerNumber: {
                get() {
                    axios({
                        method: 'post',
                        url: '/api/configurations/return_accept_CustomerNumber'
                    })
                        .then((response) => {
                            if (response.data != null) {
                                this.$store.dispatch('configurations/change_accept_CustomerNumber_storeAction', response.data);
                            }
                        });

                    return this.$store.state.configurations.accept_CustomerNumber;
                },
            },
            databaseversion: {
                get() {
                    return this.$store.state.configurations.databaseVersion;
                }
            },
            shareDisplayShow: {
                get() {
                    this.tempZALift_ID = this.$store.state.configurations.tempZALift_ID;
                    this.sharedList = this.$store.state.configurations.sharedList;
                    return this.$store.state.configurations.shareDisplayShow;
                }
            },
            searchNote: {
                get() {
                    return this.note
                },
                set(value) {
                    this.note = value;
                    this.customerNumber = null;
                    this.calculationNumber = null;
                    if (this.note != null && this.note.trim() != '') {
                        const configuration_Overview = this.$store.state.configurations.configurationOverview;
                        const filter = configuration_Overview.filter(x => x.note != null && x.note.trim().toLowerCase().indexOf(this.note.trim().toLowerCase()) > -1);
                        this.$store.dispatch('configurations/change_configurationOverviewFilter_storeAction', filter)
                        this.configurationsOverviewList = this.$store.state.configurations.configurationOverviewFilter;
                    }
                    else {
                        this.$store.dispatch('configurations/change_configurationOverviewFilter_storeAction', this.$store.state.configurations.configurationOverview)
                        this.configurationsOverviewList = this.$store.state.configurations.configurationOverviewFilter;
                    }

                }
            },
        },
        methods: {
            searchCalculationNumber() {
                if (this.calculationNumber != null && this.calculationNumber.trim() != '') {
                    if (this.calculationNumber.slice(0, 4).toLowerCase() == "zlw-") {
                        this.$store.dispatch('configurations/change_configurationOverviewFilter_storeAction', this.$store.state.configurations.configurationOverview)
                        this.configurationsOverviewList = this.$store.state.configurations.configurationOverviewFilter;

                        axios({
                            method: 'post',
                            url: '/api/configurations/search_databaseRecord_from_configurationOverviews',
                            headers: {
                                "ZAlift_Id": "" + this.calculationNumber,
                            },
                        })
                            .then((response) => {
                                this.$store.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data)
                                if (response.data.status.statusCode.startsWith('2')) {
                                    if (response.data.data.configurationOverviewsId != null) {
                                        const data = {
                                            configurationOverviewsId: response.data.data.configurationOverviewsId,
                                        }
                                        this.$store.dispatch('configurations/check_configuration_fromDatabase_storeAction', data)
                                    }
                                }
                            })
                            .catch(() => { })
                            .then(() => {
                                /* this.loader_forLoadingResults_at_configurationsOverview_Shown = false;*/
                            })
                    }
                    else {
                        const configuration_Overview = this.$store.state.configurations.configurationOverview;
                        const filter = configuration_Overview.filter(x => x.note != null && x.note.trim().toLowerCase().indexOf(this.calculationNumber.trim().toLowerCase()) > -1);
                        this.$store.dispatch('configurations/change_configurationOverviewFilter_storeAction', filter)
                        this.configurationsOverviewList = this.$store.state.configurations.configurationOverviewFilter;
                    }
                }
                else {
                    this.$store.dispatch('configurations/change_configurationOverviewFilter_storeAction', this.$store.state.configurations.configurationOverview)
                    this.configurationsOverviewList = this.$store.state.configurations.configurationOverviewFilter;
                }

            },
            searchCustomerNumber() {
                if (this.customerNumber != null && this.customerNumber.trim() != '') {

                    axios({
                        method: 'post',
                        url: '/api/configurations/search_databaseRecord_from_configurationOverviews_customerNumber',
                        headers: {
                            "erpNumber": "" + this.customerNumber,
                        },
                    })
                        .then((response) => {
                            if (response.data.data != null) {
                                this.$store.dispatch('configurations/change_configurationOverviewFilter_storeAction', response.data.data.listOfConfigurations)
                                this.$store.dispatch('configurations/change_configurationLastEditedOn_storeAction');
                                this.configurationsOverviewList = this.$store.state.configurations.configurationOverviewFilter;
                            }
                        })
                        .catch(() => { })
                        .then(() => {
                            /* this.loader_forLoadingResults_at_configurationsOverview_Shown = false;*/
                        })
                }
                else {
                    this.$store.dispatch('configurations/change_configurationOverviewFilter_storeAction', this.$store.state.configurations.configurationOverview)
                    this.configurationsOverviewList = this.$store.state.configurations.configurationOverviewFilter;
                }

            },

            check_configuration_fromFile(event) {
                const data = {
                    fileInformations: event.target.files[0],
                    fileLanguage: this.$store.state.configurations.language,
                }
                event.target.value = null;
                this.$store.dispatch('configurations/check_configuration_fromFile_storeAction', data)
            },

            furtherToConfiguration() {
                this.$store.dispatch('configurations/change_elevatorMotor_Manual_storeAction', false);
                this.$store.dispatch('inputs/change_inputs_toDefault_storeAction');
                this.$store.dispatch('driveTechnologies/change_driveTechnologies_toDefault_storeAction');
                this.$store.dispatch('controlTechnologies/change_controlTechnologies_toDefault_storeAction');
                this.$store.dispatch('frames/change_frames_toDefault_storeAction')
                this.$store.dispatch('summary/change_summary_toDefault_storeAction');
                this.$store.dispatch('configurations/change_configurations_toDefault_storeAction');

                router.push('../configurationStep/inputs');
            },
            TestDimmlerFI() {
                this.$store.dispatch('configurations/MANU', true)
                router.push('../manualStep/ManualHome');
            },
            logout() {
                this.$store.dispatch('account/logout');
            },
            addLink() {
                //this.sharedList = [];
                var email = document.getElementById("idAddToShareList").value;
                if (email.includes("@")) {
                    axios({
                        method: 'post',
                        url: '/api/configurations/save_shared_configurations',
                        headers: {
                            "ZAlift_Id": "" + this.tempZALift_ID,
                            "email": email,
                        },
                    })
                        .then((response) => {
                            if (response.data == 'NA') {
                                alert('Not authorized!')
                            } else {
                                this.sharedList.push(email);
                            }
                        })
                        .catch(() => {
                        })
                } else alert('Not a email!')


            },
            removeLink(email, event) {
                if (event.target.tagName == "I") {
                    axios({
                        method: 'post',
                        url: '/api/configurations/remove_shared_configurations',
                        headers: {
                            "ZAlift_Id": "" + this.tempZALift_ID,
                            "email": email,
                        },
                    })
                        .then((response) => {
                            if (response.data == 'NA') {
                                alert('Not authorized!')
                            } else {
                                this.sharedList = this.sharedList.filter(function (value, index, arr) {
                                    return value != email;
                                });
                            }
                            //this.sharedList.splice(email, 1);
                        })
                        .catch(() => {

                        })
                }
            },
            close() {
                //document.getElementById("shareDisplay").hidden = true;
                this.$store.dispatch('configurations/change_configurationsTable_shareDisplayShow_storeAction', false);
            }
        },
    }
</script>

<style>
    div.home .c-wrapper > div:first-child > div {
        background: linear-gradient( rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8) ) !important;
    }

    div.home .c-body {
        background: linear-gradient( rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) ) !important;
    }

    div.home .c-wrapper {
        background: url('/img/wallpaper2.png') !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
    }

    #overlay {
        /*display: none;*/
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0,0,0,0.5);
        z-index: 2;
        cursor: pointer;
    }
</style>
