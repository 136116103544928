<template>
    <div>
        <div class="ZAfont_family ZAfont_size">... {{$t('3331')}}</div>
        <div v-if="loader_forLoadingResults_at_configurationsOverview_Shown==true" style="margin-top:10px">
            <LoaderIcon v-bind:loaderSize="'lg'"></LoaderIcon>
        </div>
        <div v-else>
            <div v-if="(statusAt_responseOf_select_databaseRecord_from_configurationOverviews.hasOwnProperty('statusCode'))&&(statusAt_responseOf_select_databaseRecord_from_configurationOverviews.statusCode.startsWith('2'))">
                <div v-if="configurationsOverviewList != undefined && configurationsOverviewList.length>0">
                    <table class="my_table_configurationsOverview ZAfont_family ZAfont_size" style="margin-top:15px;">
                        <thead>
                            <tr style="display:flex;">
                                <th style="flex:0 0 10%; text-align:left">{{$t('3332')}}</th>
                                <th style="flex:0 0 25%; text-align:left">{{$t('3334')}}</th>
                                <th style="flex:0 0 15%; text-align:center">{{$t('3333')}}</th>
                                <th style="flex:0 0 22%; text-align:right">{{$t('3335')}}</th>
                                <th style="flex:0 0 22%; text-align:right">{{$t('3336')}}</th>
                                <th style="flex:0 0 5%; text-align:right">     </th>
                            </tr>
                        </thead>
                        <tbody style="height:250px;overflow-y:scroll;overflow-x:auto !important;cursor:pointer">
                            <tr v-for="configuration in configurationsOverviewList" @click="rowClick(configuration, $event)" style="display:flex;">
                                <td style="flex:0 0 10%; text-align:left">{{configuration.zAlift_ID}}</td>
                                <td style="flex:0 0 25%; text-align:left">
                                    <span v-if="configuration.note!=null">{{configuration.note}}</span>
                                    <span v-else>-</span>
                                </td>
                                <td style="flex:0 0 15%; text-align:center">
                                    <span v-if="configuration.configuration_Modifiable==false">{{$t('3337')}}</span>
                                    <span v-else>{{$t('3338')}}</span>
                                </td>
                                <td style="flex:0 0 22%; text-align:right">{{configuration.configuration_LastEditedOn}}</td>
                                <td style="flex:0 0 22%; text-align:right">{{configuration.user_MailAddress}}</td>
                                <td v-if="configuration.configuration_Modifiable==false" style="flex: 0 0 5%; text-align: right;"><span><i class="fa-light fa-share-nodes"></i></span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else>
                    <div style="display:inline-block;border:1px solid grey; padding:0.75rem; margin-top:10px">
                        <div class="my_color_00338e"><i class="fal fa-info-square fa-2x"></i></div>
                        <div>{{$t('3339')}}</div>
                        <a @click="furtherToConfiguration" href="#">{{$t('3298')}}</a>
                    </div>
                </div>
            </div>
            <div v-else>
                <div style="display:inline-block;border:1px solid grey; padding:0.75rem; margin-top:10px">
                    <div class="my_color_00338e"><i class="fal fa-info-square fa-2x"></i></div>
                    <div>{{$t('3351')}}</div>
                    <a @click="select_databaseRecord_from_configurationOverviews" href="#">{{$t('3256')}}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import router from '../../router';
    import LoaderIcon from '../loader/LoaderIcon';

    export default {
        name: 'ConfigurationsTable',
        props: {
            tableSizeLarge: {
                type: Boolean,
                default: false
            },
        },
        components: {
            LoaderIcon,
        },
        data() {
            return {
                loader_forLoadingResults_at_configurationsOverview_Shown: false,
                statusAt_responseOf_select_databaseRecord_from_configurationOverviews: {},

                sharedList: ["example@email.com", "dkdkd@com.de"],
                tempZALift_ID: "",
                calculationNumber: null,
            }
        },
        created() {
            this.select_databaseRecord_from_configurationOverviews();
        },
        computed: {
            configurationsOverviewList: {
                get() {
                    return this.$store.state.configurations.configurationOverviewFilter;
                },
            },
        },
        methods: {
            select_databaseRecord_from_configurationOverviews() {
                this.statusAt_responseOf_select_databaseRecord_from_configurationOverviews = {};
                this.loader_forLoadingResults_at_configurationsOverview_Shown = true;
                this.$store.dispatch('configurations/loadDatabaseVersion_storeAction');

                axios({
                    method: 'post',
                    url: '/api/configurations/select_databaseRecord_from_configurationOverviews',
                })
                    .then((response) => {
                        this.$store.dispatch('statusHandling/change_dataAndStatus_storeAction', response.data)
                        this.statusAt_responseOf_select_databaseRecord_from_configurationOverviews = Object.assign({}, response.data.status);

                        if (response.data.status.statusCode.startsWith('2')) {
                            this.$store.dispatch('configurations/change_configurationOverview_storeAction', response.data.data.listOfConfigurations)
                            this.$store.dispatch('configurations/change_configurationOverviewFilter_storeAction', this.$store.state.configurations.configurationOverview)
                            this.$store.dispatch('configurations/change_configurationLastEditedOn_storeAction');

                            if (this.zaliftidFromUrl != null) {
                                this.selectConfig(this.zaliftidFromUrl);
                            }
                            if (this.salesorderFromUrl != null) {
                                this.$store.dispatch('configurations/change_configurations_salesOrderRelation_storeAction', {
                                    salesOrder: this.salesorderFromUrl,
                                    pos: this.posFromUrl
                                });
                            }
                        }

                    })
                    .catch(() => { })
                    .then(() => {
                        this.loader_forLoadingResults_at_configurationsOverview_Shown = false;
                    })
            },

            furtherToConfiguration() {
                this.$store.dispatch('inputs/change_inputs_toDefault_storeAction');
                this.$store.dispatch('driveTechnologies/change_driveTechnologies_toDefault_storeAction');
                this.$store.dispatch('controlTechnologies/change_controlTechnologies_toDefault_storeAction');
                this.$store.dispatch('frames/change_frames_toDefault_storeAction')
                this.$store.dispatch('summary/change_summary_toDefault_storeAction');
                this.$store.dispatch('configurations/change_configurations_toDefault_storeAction');

                router.push('../configurationStep/inputs');
            },
            check_configuration_fromDatabase(value) {
                const data = {
                    configurationOverviewsId: value.configurationOverviewsId,
                    routeSlug: null
                }
                this.$store.dispatch('configurations/check_configuration_fromDatabase_storeAction', data);
            },
            selectConfig(zaliftId) {
                this.sharedList = [];
                this.$store.dispatch('configurations/change_configurationsTable_sharedList_storeAction', this.sharedList);
                this.tempZALift_ID = zaliftId;
                this.$store.dispatch('configurations/change_configurationsTable_tempZAliftID_storeAction', this.tempZALift_ID);

                const configuration_Overview = this.$store.state.configurations.configurationOverview;
                this.check_configuration_fromDatabase(configuration_Overview.filter(x => x.zAlift_ID == zaliftId)[0]);
            },
            rowClick(configuration, event) {
                if (event.target.tagName == "I") {
                    //document.getElementById("shareDisplay").hidden = false;
                    this.$store.dispatch('configurations/change_configurationsTable_shareDisplayShow_storeAction', true);

                    this.sharedList = [];
                    this.$store.dispatch('configurations/change_configurationsTable_sharedList_storeAction', this.sharedList);
                    this.tempZALift_ID = configuration.zAlift_ID;
                    this.$store.dispatch('configurations/change_configurationsTable_tempZAliftID_storeAction', this.tempZALift_ID);

                    axios({
                        method: 'post',
                        url: '/api/configurations/load_shared_configurations',
                        headers: {
                            "ZAlift_Id": "" + configuration.zAlift_ID,
                        },
                    })
                        .then((response) => {
                            this.sharedList = [...response.data];
                            this.$store.dispatch('configurations/change_configurationsTable_sharedList_storeAction', this.sharedList);
                        })
                        .catch(() => { })


                } else {
                    this.check_configuration_fromDatabase(configuration)
                    this.$store.dispatch('configurations/change_configurationsTable_shareDisplayShow_storeAction', false);
                }

            },
        },
    }
</script>

<style>
</style>

